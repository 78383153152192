$button-border-radius: 3px;
$button-height: 48px;

%button {
  @include font-size($font-size-base);
  font-weight: bold;
  display: inline-block;
  text-transform: none !important;
  border-radius: $button-border-radius;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  width: max-content;
  box-shadow: 0 6px 13px 2px rgba(0, 0, 0, 0.11);

  &:hover:not([disabled]),
  &[hover] {
    background-color: #9bd6cb;
  }

  &[disabled],
  &.inactive,
  &.disabled {
    opacity: 60%;
    pointer-events: none;
  }

  //for button tag instead of a
  &:focus {
    outline: none;
    box-shadow: none;
  }

  a {
    color: $white !important;
  }
}

.button-primary {
  @extend %button;
  background-color: $brand-color;
  color: $white !important;
  padding: 11px 27px;
  border: 1px solid $brand-color;
  height: $button-height;

  &:active {
    background: darken($brand-color, 10%);
  }
}

.button-secondary {
  @extend %button;
  background-color: $brand-color;
  color: $white !important;
  padding: 8px 24px;
  border: 1px solid $brand-color;
  border-radius: 24px;

  &:active {
    background: darken($brand-color, 10%);
  }

  &:after {
    content: "→";
  }
}

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.margin-top--medium {
  margin-top: 50px;
}
.margin-top--large {
  margin-top: 100px;
}
