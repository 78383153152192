@import "../../styles/variables";

.masonry {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  gap: 5px;
}
.masonry-brick {
  color: $white;
  position: relative;
  flex: auto;
  height: 300px;
  min-width: 150px;
  margin: 0;
  cursor: pointer;
  transition: 1s;

  &:hover {
    color: $white;
    transition: opacity 0.35s;

    opacity: 0.7;
    span {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(90%);
  }

  span {
    opacity: 0;
    position: absolute;
    z-index: 30;
    left: 40px;
    bottom: 30px;
    transition: 1s;
    transform: translate3d(0, 100%, 0);
    transition: opacity 0.35s, transform 0.35s;
  }
}

.masonry-brick:nth-child(4n + 1) {
  width: 250px;
}
.masonry-brick:nth-child(4n + 2) {
  width: 325px;
}
.masonry-brick:nth-child(4n + 3) {
  width: 180px;
}
.masonry-brick:nth-child(4n + 4) {
  width: 380px;
}
