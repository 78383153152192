.banner-image {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 700px;
  background-size: cover;
  background-position: center left;
  background-image: url(../../assets/banner.png);
  padding: 20px;

  .banner-image__wrapper {
    align-items: center;
  }

  .banner-image__content {
    max-width: 600px;
    margin-top: 120px;
    margin-bottom: 70px;
  }

  h2 {
    font-size: 48px;
  }
}

@media (max-width: 768px) {
  .banner-image__wrapper {
    flex-wrap: wrap;
  }
}
