@import "../../../styles/variables";

.box-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 30px 40px -20px #a3a5ae;
  padding: 30px;
  min-height: 320px;
  border-top: 3px solid $brand-color-quaternary;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.01);
  }
}

.box-card__title {
  color: $text-color !important;
}
.box-card__info {
  color: $text-color-secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.box-card__icon {
  max-width: 64px;
  margin-left: auto;
  margin-top: auto;
}
