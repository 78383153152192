@import "../../styles/variables";

.navbar-toggler {
  position: relative;
  border: 0;

  span {
    display: block;
    background-color: $brand-color;
    height: 4px;
    width: 27px;
    margin-top: 5px;
    margin-bottom: 5px;
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }

  span:nth-child(1),
  span:nth-child(3) {
    transition: transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
  }

  &:focus,
  &:active {
    outline: 0 !important;
    box-shadow: none;
  }

  &:not(.collapsed) {
    span:nth-child(1) {
      position: absolute;
      left: 12px;
      top: 10px;
      transform: rotate(135deg);
      opacity: 0.9;
    }
    span:nth-child(2) {
      visibility: hidden;
    }
    span:nth-child(3) {
      position: absolute;
      left: 12px;
      top: 10px;
      transform: rotate(-135deg);
      opacity: 0.9;
    }
  }
}

.navbar-light .navbar-nav {
  .nav-link {
    color: $brand-color-tertiary;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    background-color: white;
    text-align: center;
    box-shadow: 0 6px 13px 2px rgba(0, 0, 0, 0.11);
  }
}

.navbar {
  transition: all 0.3s ease-out 0s;
}
.navbar.sticky {
  background-color: white;
  box-shadow: 0 6px 13px 2px rgba(0, 0, 0, 0.11);
}
