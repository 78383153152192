.about__container {
  position: relative;
  margin-top: 150px;
}

@media (min-width: 992px) {
  .about__description {
    text-align: right;
    margin-top: 250px;
  }
  .about__title {
    font-size: 58px;
    position: absolute;
    right: 0;
    top: 128px;
  }
}

@media (min-width: 1468px) {
  .about__title {
    font-size: 86px;
  }
}

.about-teaser__image {
  max-width: 300px;
  margin: auto;
}

.about {
  .banner {
    height: 700px;
  }
}
