.banner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center left;
  padding: 20px;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.16);
}

.banner__content {
  position: relative;
  color: white;
}

.banner__title {
  font-weight: 700;
}

.banner__title {
  font-size: 48px;
}
.banner__description {
  font-size: 18px;
}
