.banner-home {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 800px;
  background-size: cover;
  background-position: center left;
  background-image: url(../../assets/banner.png);
  padding: 20px;

  .banner-home__content {
    max-width: 600px;
  }
}

.banner-home__container {
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  margin-bottom: 70px;

  .button-primary {
    margin-top: 30px;
  }
}

@media (max-width: 998px) {
  .banner-home__container {
    justify-content: center;
    flex-wrap: wrap;
  }
  .banner-home__image {
    max-width: 400px;
    margin-top: 30px;
  }

  .banner-home {
    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 36px;
    }
  }
}

@media (min-width: 998px) {
  .banner-home__image {
    max-width: 550px;
  }

  .banner-home {
    h1 {
      font-size: 80px;
      font-weight: 700;
    }
    h2 {
      font-size: 48px;
    }
  }
}
