@import "../../styles/variables";

.simple-card {
  display: flex;
  flex-direction: column;
  color: $text-color !important;
  padding: 30px 20px;
  min-height: 250px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px #00000040;
  transition: all 0.2s;
  border-top: 3px solid $brand-color-quaternary;

  a {
    color: $text-color;
  }

  &:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
  }
}

.simple-card__headline {
  font-style: italic;

  &:after {
    background-color: $brand-color;
    content: "";
    display: block;
    height: 2px;
    margin-top: 6px;
    width: 30px;
  }
}

.simple-card__title {
  color: $text-color;
  margin-bottom: 30px;
}

.simple-card__info {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: auto;
}
