.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #212529;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 50;
}

.footer h5 {
  font-size: 1em;
  color: white;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.footer-icons {
  list-style-type: none;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 30px;
}

.social-icons {
  font-size: 20px;
  display: inline-block;
}

@media (max-width: 998px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
  }

  .footer-icons {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
