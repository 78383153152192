@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import "~bootstrap/scss/bootstrap";
@import "./variables";
@import "./buttons";

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Ubuntu", sans-serif;
}

.logo {
  width: 60px;
}

a {
  color: $brand-color;
  text-decoration: none;

  &:visited,
  &:focus,
  &:hover,
  &:visited {
    color: $brand-color;
  }
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.color--quarternary {
  color: $brand-color-quaternary;
}

.color--secondary {
  color: $brand-color-secondary;
}

.bg-color-gray {
  background-color: transparentize($gray, 0.75);
}

.font-size-medium {
  font-size: 20px;
  line-height: 1.6;
}

.max-width-250 {
  max-width: 250px;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}
