$white: #fff;
$black: #000;
$gray: #c4c4c4;
$brand-color: #80ccbe;
$brand-color-secondary: #007e87;
$brand-color-tertiary: #427d70;
$brand-color-quaternary: #29a0b1;

$text-color: #25282b;
$text-color-secondary: #a3a5ae;
