@import "../../styles/variables";

.blog-card {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  max-width: 360px;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.01);
  }
}

.blog-card__img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card__body {
  display: flex;
  flex-direction: column;
  color: $text-color;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 200px;
}

.blog-card__description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $text-color-secondary;
  font-size: 14px;
}

@media (max-width: 768px) {
  .blog-card {
    margin: 0 auto;
  }
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.tag-teal {
  background-color: #47bcd4;
}
.tag-purple {
  background-color: #5e76bf;
}
.tag-pink {
  background-color: #cd5b9f;
}
